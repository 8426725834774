import React from 'react';
import { FulfillmentType } from 'src/helpers/Constants';
import { purchaseDetailsVariant } from 'tailwindVariants/components/purchaseDetailsTailwindVariant';
import { PurchaseDetailsProps } from './Details';
import { Text } from '@sitecore-jss/sitecore-jss-nextjs';
import useDictionary from 'src/hooks/useDictionary';
import { DiscreteLineItem } from '../Constants';
import { TaxDetails } from '../../redux/xp';
export default function PurchaseDetailsOrderSummary({
  fields,
  orderData,
  className,
}: { className?: string } & PurchaseDetailsProps) {
  const {
    orderSummaryWrapper,
    summaryTitle,
    orderSummaryDetails,
    orderRaw,
    orderInnerRow,
    smallRegularLabel,
    smallBoldLabel,
  } = purchaseDetailsVariant({
    size: {
      initial: 'mobile',
      lg: 'desktop',
    },
  });
  const { getDictionaryValue } = useDictionary();

  const delivery: boolean = orderData?.Order?.xp?.Fulfillment == FulfillmentType?.DFS;
  //check order has delivery tip or not
  const deliveryTip = orderData?.LineItems?.filter(
    (product) => product?.ProductID == DiscreteLineItem.TIP
  )?.[0]?.LineTotal;
  const taxDetail: TaxDetails = orderData?.OrderCalculateResponse?.xp?.TaxDetails;
  const retailDeliveryFee =
    taxDetail?.LineItems?.find((l) => l.LineItemID == 'RetailDeliveryFee')?.LineItemTotalTax ?? 0;
  return (
    <div
      className={orderSummaryWrapper({ className: className })}
      data-component={'/helpers/purchaseDetails/PurchaseDetailsOrderSummary.tsx'}
      data-wrapper="order-summary"
    >
      <Text tag="span" field={fields?.title} className={summaryTitle()} />
      <div className={orderSummaryDetails()}>
        <div className={orderRaw()}>
          <div className={orderInnerRow({ className: 'flex-col' })}>
            <Text tag="div" field={fields?.paymentMethodText} className={smallBoldLabel()} />
            <div>
              <Text
                tag="span"
                field={fields?.creditCardEndingText}
                className={smallRegularLabel()}
              />
              <span className={smallRegularLabel()}>{orderData?.Order?.xp?.CardEndingIn}</span>
            </div>
          </div>
        </div>
        <div className={orderRaw({ className: '!border-b-0' })}>
          <div className={orderInnerRow()}>
            <Text tag="span" field={fields?.orderSummaryText} className={smallBoldLabel()} />
          </div>
        </div>
        <div className={orderRaw()}>
          <div className={orderInnerRow()}>
            <Text tag="span" field={fields?.listPriceText} className={smallRegularLabel()} />
            <span className={smallRegularLabel()}>
              {' '}
              {getDictionaryValue('CurrencySymbol')}
              {orderData?.Order?.xp?.EmailContent?.ListPrice?.toFixed(2)}
            </span>
          </div>
        </div>
        {orderData?.Order?.xp?.EmailContent?.TotalMemberSaving &&
        orderData?.Order?.xp?.EmailContent?.TotalMemberSaving > 0 ? (
          <div className={orderRaw()}>
            <div className={orderInnerRow()}>
              <Text tag="span" field={fields?.totalMemberSavingText} className={smallBoldLabel()} />
              <div>
                <span className={smallBoldLabel()}>
                  {getDictionaryValue('CurrencySymbol')}
                  {orderData?.Order?.xp?.EmailContent?.TotalMemberSaving?.toFixed(2)}
                </span>
              </div>
            </div>
            {orderData?.Order?.xp?.EmailContent?.SalePrice &&
            orderData?.Order?.xp?.EmailContent?.SalePrice > 0 ? (
              <div className={orderInnerRow({ className: 'pl-desk-margin-tight-left' })}>
                <Text tag="span" field={fields?.salePriceText} className={smallRegularLabel()} />
                <div className={smallRegularLabel()}>
                  {getDictionaryValue('CurrencySymbol')}
                  <span className={smallRegularLabel()}>
                    {orderData?.Order?.xp?.EmailContent?.SalePrice?.toFixed(2)}
                  </span>
                </div>
              </div>
            ) : null}
            {orderData?.Order?.xp?.EmailContent?.AutoshipDiscount &&
            orderData?.Order?.xp?.EmailContent?.AutoshipDiscount > 0 ? (
              <div className={orderInnerRow({ className: 'pl-desk-margin-tight-left' })}>
                <Text
                  tag="span"
                  field={fields?.autoshipDiscountText}
                  className={smallRegularLabel()}
                />
                <div className={smallRegularLabel()}>
                  {getDictionaryValue('CurrencySymbol')}
                  <span className={smallRegularLabel()}>
                    {orderData?.Order?.xp?.EmailContent?.AutoshipDiscount?.toFixed(2)}
                  </span>
                </div>
              </div>
            ) : null}
            {orderData?.Order?.xp?.EmailContent?.Coupons &&
            orderData?.Order?.xp?.EmailContent?.Coupons?.length > 0 ? (
              <div className={orderInnerRow({ className: 'pl-desk-margin-tight-left gap-1' })}>
                <Text tag="span" field={fields?.couponText} className={smallRegularLabel()} />
                {orderData?.Order?.xp?.EmailContent?.Coupons?.map((coupon) => {
                  return coupon?.CouponDiscount && Number(coupon?.CouponDiscount) > 0 ? (
                    <>
                      <span className={smallRegularLabel({ className: 'grow' })}>
                        {coupon?.Coupon}
                      </span>
                      <div key={coupon?.Coupon} className={smallRegularLabel()}>
                        {getDictionaryValue('CurrencySymbol')}
                        <span>{Number(coupon?.CouponDiscount)?.toFixed(2)}</span>
                      </div>
                    </>
                  ) : null;
                })}
              </div>
            ) : null}
          </div>
        ) : null}
        <div className={orderRaw()}>
          <div className={orderInnerRow()}>
            <Text tag="span" field={fields?.subTotalText} className={smallBoldLabel()} />
            <div>
              {getDictionaryValue('CurrencySymbol')}
              <span className={smallRegularLabel()}>
                {orderData?.Order?.xp?.EmailContent?.Subtotal?.toFixed(2)}
              </span>
            </div>
          </div>
          {orderData?.Order?.ShippingCost && orderData?.Order?.ShippingCost > 0 ? (
            <div className={orderInnerRow()}>
              <Text tag="span" field={fields?.deliveryFeeText} className={smallRegularLabel()} />
              <div>
                {getDictionaryValue('CurrencySymbol')}
                <span className={smallRegularLabel()}>
                  {orderData?.Order?.ShippingCost?.toFixed(2)}
                </span>
              </div>
            </div>
          ) : null}
          {/* CO Delivery Fee */}
          {delivery && retailDeliveryFee > 0 ? (
            <div className={orderInnerRow()}>
              <Text
                tag="span"
                field={fields?.coRetailDeliveryFeeText}
                className={smallRegularLabel()}
              />
              <div>
                {getDictionaryValue('CurrencySymbol')}
                <span className={smallRegularLabel()}>{retailDeliveryFee?.toFixed(2)}</span>
              </div>
            </div>
          ) : null}
          {deliveryTip && deliveryTip > 0 ? (
            <div className={orderInnerRow()}>
              <Text tag="span" className={smallRegularLabel()} field={fields?.deliveryTipText} />
              <div>
                {getDictionaryValue('CurrencySymbol')}
                <span className={smallRegularLabel()}>{deliveryTip?.toFixed(2)}</span>
              </div>
            </div>
          ) : null}
          <div className={orderInnerRow()}>
            <Text tag="span" field={fields?.taxText} className={smallRegularLabel()} />
            <div>
              {getDictionaryValue('CurrencySymbol')}
              <span className={smallRegularLabel()}>
                {((orderData?.Order?.TaxCost ?? 0) - retailDeliveryFee).toFixed(2)}
              </span>
            </div>
          </div>
        </div>
        <div className={orderRaw({ className: '!border-b-0' })}>
          <div className={orderInnerRow()}>
            <Text tag="span" field={fields?.totalText} className={smallBoldLabel()} />
            <span className={smallRegularLabel()}>
              {getDictionaryValue('CurrencySymbol')}
              {orderData?.Order?.Total?.toFixed(2)}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
