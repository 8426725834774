import { WidgetDataType, useRecommendation, widget } from '@sitecore-search/react';
import { RichText, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import React, { useEffect, useState } from 'react';
import { sendProductsPromotion } from 'src/utils/sendGTMEvent';
import { FulfillmentType, GTM_EVENT, GTMLabels } from 'src/helpers/Constants';
import { useOcSelector } from 'src/redux/ocStore';
import { useRouter } from 'next/router';
import { ProductSearchResultModelWithVariants } from '../search/SearchResults/types';
import { useProductVariants } from '../search/SearchResults/use-product-variants';
import { frequentlyBroughtProductVariants } from 'tailwindVariants/components/frequentlyBroughtPtoductTailwindVariants';
import IconHelper from '../commonComponents/IconHelper';
import ImageHelper from '../commonComponents/ImageHelper';
import { getFirstProductImage } from '../productDetailHelper';
import { ProductRecommendationPrice } from '../search/SearchResults/products/ProductPrice';
import { getProductPriceFromSearch, UIProductPrice } from '../ProductPriceUI';
import formatPrice from 'src/utils/formatPrice';
import { ProductLink } from '../search/SearchResults/products/ProductLink';
import { useHeaderContext } from 'lib/context/HeaderComponentContext';
import useOcCart from 'src/hooks/useOcCart';
import FrequentlyBoughtTogetherSkeleton from './ProductTileSkeleton';
import { FrequentlyBroughtProductProps } from 'components/FrequentlyBrought/FrequentlyBrought';
import useDictionary from 'src/hooks/useDictionary';
import AddToCartWarningModal from '../purchaseDetails/AddToCartWarningModal';

//component variant

//types
interface FrequentlyBroughtProps extends Pick<FrequentlyBroughtProductProps, 'fields'> {
  brandName?: string;
}

interface SelectedProduct {
  [key: string]: boolean;
}
//main component
const FrequentlyBroughtProduct: React.FC<FrequentlyBroughtProps> = ({ fields }): JSX.Element => {
  //destructuring the variants
  const {
    mainWrapper,
    container,
    base,
    heading,
    titleAndProductWrapper,
    componentWrapper,
    productImage,
    productWrapper,
    productTile,
    detailWrapper,
    productName,
    plusIcon,
    productPriceText,
    checkBox,
    inputCheck,
    checkmarkIcon,
    productAndCtaWrapper,
    selectAndDetailsWrapper,
    totalAndCTAWrapper,
    imageWrapper,
    addToCartBtn,
    listPrice,
    totalPriceText,
  } = frequentlyBroughtProductVariants({
    size: { initial: 'mobile', lg: 'desktop' },
  });

  const { getDictionaryValue } = useDictionary();
  const myStoreData = useOcSelector((state) => state?.storeReducer?.selectedStore);
  const ecommerceDisabled = myStoreData?.ecommerceDisabled;
  const currOrder = useOcSelector((state) => state?.ocCurrentOrder);
  const pickup: boolean = currOrder?.order?.xp?.Fulfillment === FulfillmentType.BOPIS;
  const { headerContextData, setHeaderContextData } = useHeaderContext();
  const { handleAddAllToCart } = useOcCart();
  const [selectedProducts, setSelectedProducts] = useState<SelectedProduct>({});
  const [totalPrice, setTotalPrice] = useState(0);
  const [isAddToCartDisabled, setIsAddToCartDisabled] = useState(false);
  const [onlyOneSelected, setOnlyOneSelected] = useState(false);
  const [hasMemberPrice, setHasMemberPrice] = useState(false);
  const [hasImapPrice, setHasImapPrice] = useState(false);
  const [hasAllProductsUnselected, setHasAllProductsUnselected] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dummyProductImage = useOcSelector(
    (state) => state?.otherSettingSlice?.data?.noProductImage
  );
  const router = useRouter();

  //calling an useRecommendation for getting the discoverData
  const {
    widgetRef,
    actions,
    queryResult: { isFetching, isLoading, data: { content: products = [], total_item = 0 } = {} },
  } = useRecommendation({
    state: {
      itemsPerPage: 3,
    },
  });

  const productsWithVariants = useProductVariants(products);

  //sending a product data on GTM, on change of product data
  useEffect(() => {
    let timeOutId: NodeJS.Timeout;
    const checkGtmLoad = () => {
      const isEventFired =
        window &&
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any)['dataLayer']?.filter(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (item: any) =>
            item.ecommerce?.items &&
            item.ecommerce.items.some(
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (i: any) => i.item_list_id === GTMLabels?.frequentlyBoughtTogether
            )
        )?.length > 0;
      const isGTMLoad =
        //eslint-disable-next-line @typescript-eslint/no-explicit-any
        typeof window !== 'undefined' && (window as any)['google_tag_manager']?.dataLayer?.gtmLoad;
      if (productsWithVariants && productsWithVariants?.length > 0 && !isEventFired && isGTMLoad) {
        sendProductsPromotion({
          eventName: GTM_EVENT?.viewItemList,
          data: productsWithVariants as ProductSearchResultModelWithVariants[],
          currentPage: GTMLabels?.frequentlyBoughtTogether,
          pageItem: GTMLabels?.frequentlyBoughtTogether,
          affiliation: myStoreData?.storeId,
        });
        clearTimeout(timeOutId);
      } else {
        timeOutId = setTimeout(() => {
          checkGtmLoad();
        }, 1000);
      }
    };
    checkGtmLoad();
    return () => clearTimeout(timeOutId);
  }, [productsWithVariants]);

  //setting dynamic price using sum of selected products
  useEffect(() => {
    const allProductsUnselected =
      selectedProducts && Object.values(selectedProducts).every((selected) => selected === false);

    const selectedCount = Object.values(selectedProducts).filter((value) => value === true).length;

    // Set the flag if exactly one product is selected
    setOnlyOneSelected(selectedCount === 1);

    let hasMemberPriceFound = false;
    let hasImapPriceFound = false;
    if (allProductsUnselected) {
      setHasAllProductsUnselected(true);
      setIsAddToCartDisabled(true);
    } else {
      setHasAllProductsUnselected(false);
      const newTotal = productsWithVariants?.slice(0, 3).reduce((total, product) => {
        const priceInfo = getProductPrice(getProductPriceFromSearch(product));
        const price = priceInfo?.price || 0;
        if (selectedProducts[product.id as string]) {
          if (priceInfo?.isMemberPrice === true) {
            hasMemberPriceFound = true;
          }
          if (priceInfo?.isImapPrice === true) {
            hasImapPriceFound = true;
          }
          return total + price;
        }
        return total;
      }, 0);
      setTotalPrice(newTotal);
      setIsAddToCartDisabled(false);
      setHasMemberPrice(hasMemberPriceFound);
      setHasImapPrice(hasImapPriceFound);
    }
  }, [selectedProducts, productsWithVariants]);

  useEffect(() => {
    const productIdMap = productsWithVariants
      ?.slice(0, 3)
      .reduce((acc: SelectedProduct, product) => {
        acc[product.id as string] = true;
        return acc;
      }, {});
    setSelectedProducts(productIdMap);
  }, [productsWithVariants]);

  const handleProductSelection = (productId: string) => {
    setSelectedProducts({
      ...selectedProducts,
      [productId]: !selectedProducts[productId],
    });
  };

  //getting price for specific product
  const getProductPrice = (productPrice: UIProductPrice) => {
    if (productPrice?.showViewPriceInCart && productPrice.hasListPrice) {
      return { price: productPrice.imapPrice, isMemberPrice: true, isImapPrice: true };
    }
    if (productPrice.hasListPrice && !productPrice.hasListandMemberPrice) {
      return { price: productPrice.listPrice, isMemberPrice: false };
    }
    if (productPrice.hasMemberPrice && !productPrice.hasListandMemberPrice) {
      return { price: productPrice.memberPrice, isMemberPrice: true };
    }
    if (productPrice.hasImapPrice && !productPrice.hasListandMemberPrice) {
      return { price: productPrice.imapPrice, isMemberPrice: true, isImapPrice: true };
    }
    if (productPrice.hasListandMemberPrice) {
      return { price: productPrice.memberPrice, isMemberPrice: true };
    } else {
      return { price: productPrice.listPrice, isMemberPrice: false };
    }
  };

  //handling add to Cart CTA action
  const handleAddToCart = async () => {
    const filteredProducts = productsWithVariants
      ?.map((x, i) => {
        return { ...x, index: i };
      })
      ?.filter((product) => selectedProducts[product?.id as string] === true);
    const productToAdd = filteredProducts?.map((x) => {
      return { productId: x?.id as string, isAutoship: x?.xp?.Autoship as boolean };
    });

    setHeaderContextData({
      ...headerContextData,
      openMiniCart: true,
      showMiniCartLoader: true,
    });
    const res = await handleAddAllToCart({
      productIds: productToAdd,
      storeId: myStoreData?.storeId,
    });
    sendProductsPromotion({
      eventName: GTM_EVENT?.addToCart,
      data: filteredProducts as ProductSearchResultModelWithVariants,
      click_from: GTMLabels?.frequentlyBoughtTogether,
      storeId: myStoreData?.storeId,
      fulfillment_option: !pickup ? GTMLabels?.DFS : GTMLabels?.BOPIS,
      shouldPreciousDataLaLayerRemoved: true,
      pageItem: GTMLabels?.frequentlyBoughtTogether,
      currentPage: GTMLabels?.frequentlyBoughtTogether,
    });

    if (res) {
      if ((res as { isPartiallyAdded: boolean })?.isPartiallyAdded) {
        setIsModalOpen(true);
        setHeaderContextData({
          ...headerContextData,
          showMiniCartLoader: false,
        });
      } else {
        setHeaderContextData({
          ...headerContextData,
          openMiniCart: true,
          showMiniCartLoader: false,
        });
      }
    }
  };

  const productLength = productsWithVariants?.length <= 3 ? productsWithVariants?.length : 3;
  if (!isLoading && !isFetching && products && products?.length < Number(fields?.minRecords?.value))
    return <></>;

  if (!isLoading && !isFetching) {
    if (total_item < Number(Number(fields?.minRecords?.value)) || total_item === 0) {
      return <></>;
    }
  }

  return (
    <div
      ref={widgetRef}
      data-component={'helpers/search/FrequentlyBrought'}
      className={mainWrapper()}
    >
      <div className={container()}>
        <div className={base()}>
          {!isFetching &&
          !isLoading &&
          productsWithVariants &&
          productsWithVariants?.length > 0 &&
          router?.isReady ? (
            <div className={componentWrapper()}>
              <AddToCartWarningModal
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                isPartiallyAdded={true}
                modalCTA={{ value: 'Ok' }}
                modalDescription={{
                  value:
                    'Not all products are currently available and only those products available have been added to the cart.!',
                }}
                modalTitle={{ value: 'Warning: Add all to cart !' }}
              />
              <div className={titleAndProductWrapper()}>
                <div className={heading()}>
                  <RichText field={fields?.heading} />
                </div>
                <div className={productAndCtaWrapper()}>
                  <div className={productWrapper()}>
                    {productsWithVariants?.slice(0, 3)?.map((product, index) => {
                      const image = getFirstProductImage(
                        product?.xp?.Images || product?.xp?.Images
                      );
                      const productPriceForPLP = getProductPriceFromSearch(product);
                      return (
                        <div key={product?.id} className="flex">
                          <div className={productTile()}>
                            <div className={imageWrapper()}>
                              <ProductLink {...{ actions, index, product: product }}>
                                <ImageHelper
                                  className={productImage()}
                                  field={{
                                    value: {
                                      src: image?.Url || dummyProductImage?.jsonValue?.value?.src,
                                      alt:
                                        product?.name || dummyProductImage?.jsonValue?.value?.alt,
                                      width: 208,
                                      height: 200,
                                    },
                                  }}
                                />
                              </ProductLink>
                            </div>
                            <div className={selectAndDetailsWrapper()}>
                              <div className={checkBox()}>
                                <label htmlFor={product?.id as string}>
                                  <input
                                    id={product?.id as string}
                                    name={product?.id as string}
                                    type="checkbox"
                                    className={inputCheck()}
                                    value={product?.id}
                                    checked={selectedProducts[product?.id as string]}
                                    onChange={() => {
                                      handleProductSelection(product?.id as string);
                                    }}
                                  />
                                  <IconHelper className={checkmarkIcon()} icon={'icon-checkmark'} />
                                </label>
                              </div>
                              <div className={detailWrapper()}>
                                <ProductLink {...{ actions, index, product: product }}>
                                  <h2 className={productName()}>{product?.name}</h2>
                                </ProductLink>
                                <div>
                                  <ProductRecommendationPrice
                                    productPrice={productPriceForPLP}
                                    className={productPriceText()}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          {index + 1 < productLength && (
                            <IconHelper icon="akar-plus" className={plusIcon()} />
                          )}
                        </div>
                      );
                    })}
                  </div>
                  <div className={totalAndCTAWrapper()}>
                    <h3 className="flex items-center">
                      {hasAllProductsUnselected ? (
                        getDictionaryValue('selectAtLeastOneItem')
                      ) : !hasImapPrice ? (
                        <>
                          <Text field={fields?.totalPrice} tag="h3" className={totalPriceText()} />
                          <span
                            className={listPrice({
                              className: hasMemberPrice && 'text-color-accent-red',
                            })}
                          >
                            {formatPrice(totalPrice)}
                          </span>
                        </>
                      ) : null}
                    </h3>
                    <button
                      className={addToCartBtn()}
                      disabled={isAddToCartDisabled || ecommerceDisabled === 1}
                      onClick={() => {
                        handleAddToCart();
                      }}
                    >
                      {!hasImapPrice
                        ? !onlyOneSelected
                          ? fields?.addtoCartAllText?.value
                          : fields?.addtoCartText?.value
                        : getDictionaryValue('ViewPriceInCartPDP')}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <FrequentlyBoughtTogetherSkeleton />
          )}
        </div>
      </div>
    </div>
  );
};

//creating widget
const FrequentlyBroughtProductWidget = widget(
  FrequentlyBroughtProduct,
  WidgetDataType.RECOMMENDATION,
  'product'
);

export default FrequentlyBroughtProductWidget;
